
import DetailPostage from './detail_postage.vue';
import DetailSurcharge from './detail_surcharge.vue';
import DetailScript from './detail_script.vue';

export default {
    components: {
        DetailPostage,
        DetailSurcharge,
        DetailScript
    },
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 提交状态
            loading: false,
            // 弹窗标题
            title: '',
            // 活动Tab
            activeKey: '1'
        };
    },
    watch: {
        data() {
            this.title = (this.data || {}).name || '';
        }
    },
    methods: {
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        onClose() {
            this.updateVisible(false);
        }
    }
}
